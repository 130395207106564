<template>
  <section class="hero is-fullheight">
    <ModalStatusQuotation
      v-if="activeModalStatusQuotation"
      :active="activeModalStatusQuotation"
      :status-id="detailQuote.status_id"
      :status-quotation-list="statusQuotationList"
      @close-modal="activeModalStatusQuotation = false"
      :changeStatusAction="changeStatusAction"
      @success-update-status="successUpdateStatus"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <template v-if="loading">
            <Loading />
          </template>
          <template v-else>
            <div class="column is-12">
              <status-quotation
                :status-id="detailQuote.status_id"
                :status-name="detailQuote.status_name"
                @set-moda-sale-status="activeModalStatusQuotation = true"
              />
            </div>
            <div class="column is-12">
              <accordion-quotation-detail
                :detail-quote="detailQuote"
                :products-quote="productsQuote"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'QuotationMainDetail',
  components: {
    AccordionQuotationDetail: () => import('@/components/Quotation/Detail/AccordionQuotationDetail.vue'),
    ModalStatusQuotation: () => import('@/components/Quotation/Detail/ModalStatusQuotation.vue'),
    // Se reutilizan componentes de ventas
    StatusQuotation: () => import('@/components/Sales/Detail/StatusSale.vue'),
    Loading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      activeModalStatusQuotation: false,
      loading: false,
      detailQuote: {},
      productsQuote: [],
      statusQuotationList: [],
      quoteId: null,
      empty: 0
    }
  },
  methods: {
    ...mapActions(['getQuoteDetail', 'getStatusSaleQuote', 'updateStatusQuote']),
    async getQuoteDetailAction () {
      const { success, productsQuote, quoteInfo } = await this.getQuoteDetail(this.quoteId)
      if (success) {
        this.detailQuote = quoteInfo
        this.productsQuote = productsQuote
      }
      this.loading = false
    },
    async changeStatusAction (statusSelect) {
      const dataUpdate = {
        quoteId: this.quoteId,
        statusId: statusSelect
      }
      const { success } = await this.updateStatusQuote(dataUpdate)
      if (success) {
        this.getQuoteDetailAction()
        this.activeModalStatusQuotation = false
      }
    },
    successUpdateStatus () {
      this.getQuoteDetailAction()
      this.activeModalStatusQuotation = false
    }
  },
  async beforeMount () {
    this.loading = true
    const { success, status } = await this.getStatusSaleQuote()
    if (success) { this.statusQuotationList = status }

    this.quoteId = await this.$route.params.quotationId
    this.getQuoteDetailAction()
  }
}
</script>

<style lang="scss" scoped>

</style>
